import React, { Component } from 'react';
import {Scrollbars} from "react-custom-scrollbars";
import propTypes from 'prop-types';

export default class CustomSelect extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isDropdownOpen: false,
      isAllSelected: false
    }
  }

  componentDidMount() {
    let { isAllSelected } = this.state;
    const { selectedItems, allItems } = this.props;
    isAllSelected = selectedItems.length === allItems.length;
    this.setState({
      isAllSelected
    }, () => {
      this.handlePlaceholderText();
    });
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillReceiveProps(np) {
    let { isAllSelected } = this.state;
    const { selectedItems, allItems } = np;
    isAllSelected = selectedItems.length === allItems.length;
    this.setState({
      isAllSelected
    }, () => {
      this.handlePlaceholderText();
    });
  }

  handlePlaceholderText = () => {
    const { allItems, selectedItems, placeHolderPlural, placeHolderSingular } = this.props;
    const searchElement = this.refs.searchInput;

    let placeholderText = `Select ${placeHolderSingular}`;

    if (selectedItems.length === 1) {
      placeholderText = `${selectedItems.length} ${placeHolderSingular} Selected`;
    }

    if (selectedItems.length > 1) {
      placeholderText = `${selectedItems.length} ${placeHolderPlural} Selected`;
    }

    if (selectedItems.length === allItems.length) {
      placeholderText = `All ${placeHolderPlural} Selected`;
    }

    searchElement.placeholder = placeholderText;
  }

  handleOutsideClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({
        isDropdownOpen: false
      });
    }
  }

  handleStateChangeFocus = () => {
    this.setState({
      isDropdownOpen: true
    });
  };

  handleSelectAll = () => {
    const { isAllSelected } = this.state;
    const { allItems, listName } = this.props;

    const items = isAllSelected
      ? []
      : allItems.map(filter => filter.id);
    this.props.action(listName, items);
  };

  isItemSelected = state => {
    const { selectedItems } = this.props;
    return selectedItems.indexOf(state) === -1 ? false : true;
  };

  handleSelectItem = item => {
    let { selectedItems, listName, disableValue, allItems} = this.props;
    const selectedIndex = selectedItems.indexOf(item);
    const allUserIndex = selectedItems.indexOf('all_users');

    if(listName === 'audience') {
      if (selectedIndex === -1 && item === 'all_users') {
        selectedItems = allItems.map(filter => filter.id);
      } else if (selectedIndex === 0 && item === 'all_users') {
        selectedItems = [];
      } else if(selectedIndex === -1 ) {
        selectedItems.push(item);
      } else if(selectedIndex === -1) {
        selectedItems.push(item);
      } else  if((selectedIndex === 1) && item !== 'all_users'){
        selectedItems.splice(selectedIndex, 1);
        selectedItems.splice(allUserIndex, 1);
      } else if((selectedIndex === 0) && item !== 'all_users') {
        selectedItems.splice(selectedIndex, 1);
      }

      if(selectedItems.length === allItems.length -1){
        selectedItems = allItems.map(filter => filter.id);
      }
     
    } else {
      if (selectedIndex === -1) {
        selectedItems.push(item);
      } else {
        selectedItems.splice(selectedIndex, 1);
      }
    }

    this.props.action(listName, selectedItems);
  };

  isDisabled = item => {
    const { disableValue } = this.props;
    const isDisableValueSelected = this.isItemSelected(disableValue);
    return isDisableValueSelected && disableValue && (disableValue !== item);
  }

  render() {
    const { allItems, isSelectAllRequired,
      placeHolderSingular } = this.props;
    const { isDropdownOpen, isAllSelected } = this.state;

    const items = allItems.map(item => {
      return (
        <div className="states-block" key={item.id}>
          <input
            type="checkbox"
            className="checkbox-input"
            id={item.id}
            checked={this.isItemSelected(item.id)}
          //  disabled={this.isDisabled(item.id)}
            onChange={() => {
              this.handleSelectItem(item.id);
            }}
          />
          <label htmlFor={item.id} className="checkbox-label">
            {item.name}
          </label>
        </div>
      );
    });
    return (
      <div className="custom-select-box-wrapper clearfix" ref={node => this.node = node}>
        <input
          type="text"
          className="form-control search-state"
          placeholder={`Select ${placeHolderSingular}`}
          ref="searchInput"
          readOnly={true}
          onFocus={this.handleStateChangeFocus}
        />
      {isDropdownOpen && allItems.length > 0 && (
        <div className="states-result broadcast-audience">
          <Scrollbars style={{width: "100%", height: 185}}>
            <div className="blocks-wrapper">
              {(allItems.length === 1)?
                null
                :
                ( isSelectAllRequired && 
                  <div className="states-block">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={isAllSelected}
                    id="select_all"
                    onChange={this.handleSelectAll}
                  />
                  <label
                    htmlFor="select_all"
                    className="checkbox-label select-all"
                  >
                    All
                  </label>
                </div> )
              }
              {items}
            </div>
          </Scrollbars>
        </div>
      )}
    </div>
  );
  }
}