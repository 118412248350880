import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, Modal, ModalHeader, ModalBody, FormGroup, TabContent, TabPane,
  Nav, NavItem, NavLink, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Error from '../../shared/components/form/Error';
import { updateLogo, addNewFacility, initiatePopups } from '../../redux/actions/facilityActions';
import { toggleLoading } from '../../redux/actions/commonActions';
import constant from "../../constants/responseMessages";
import config from "../../config/app.config";
import MaskedInput from "react-maskedinput";
import { validate, reset } from '../../libs/validationHelper';
import PlacesAutoComplete from '../common/PlacesAutocomplete';

class AddFacility extends PureComponent {
  constructor() {
    super();

    this.state = {
      modal: false,
      activeTab: '1',
      inputs: {
        logo: null,
        logoIcon: null,
        name: '',
        displayName: '',
        abbreviation: '',
        groupAffiliation: '',
        address: '',
        streetNo: '',
        city: '',
        address2: '',
        state: '',
        zipCode: '',
        placeId: '',
        billingAddress: '',
        billingStreetNo: '',
        billingCity: '',
        billingAddress2: '',
        billingState: '',
        billingZipCode: '',
        billingEmail: '',
        billingTier: '',
        pharmacyPhone: '',
        pharmacyEmail: '',
        pharmacyWeb: '',
        subscription: '',
        notes: '',
        tabletsPurchased: '',
        billingSameAddress: false,
        phone: '',
        website: '',
      },
      error: false,
      errors: {
        name: { tab: 1, error: null, display: 'facility name' },
        displayName: { tab: 1, error: null, display: 'display name' },
        abbreviation: { tab: 1, error: null, display: 'abbreviation' },
        groupAffiliation: { tab: 1, error: null, display: 'group affiliation' },
        address: { tab: 2, error: null, display: 'address' },
        streetNo: { tab: 2, error: null, display: 'streetNo' },
        city: { tab: 2, error: null, display: 'city' },
        address2: { tab: 2, error: null, display: 'address2' },
        state: { tab: 2, error: null, display: 'state' },
        zipCode: { tab: 2, error: null, display: 'zip code' },
        billingAddress: { tab: 3, error: null, display: 'address' },
        billingStreetNo: { tab: 3, error: null, display: 'streetNo' },
        billingCity: { tab: 3, error: null, display: 'city' },
        billingAddress2: { tab: 3, error: null, display: 'address2' },
        billingState: { tab: 3, error: null, display: 'state' },
        billingZipCode: { tab: 3, error: null, display: 'zip code' },
        billingEmail: { tab: 3, error: null, display: 'billing email' },
        billingTier: { tab: 3, error: null, display: 'billing tier' },
        pharmacyPhone: { tab: 4, error: null, display: 'phone number' },
        pharmacyEmail: { tab: 4, error: null, display: 'email' },
        pharmacyWeb: { tab: 4, error: null, display: 'web url' },
        subscription: { tab: 3, error: null, display: 'subscription' },
        notes: { tab: 3, error: null, display: 'notes' },
        tabletsPurchased: { tab: 3, error: null, display: 'tablets purchased' },
        billingSameAddress: { tab: 3, error: null, display: '' },
        phone: { tab: 5, error: null, display: 'phone number' },
        website: { tab: 5, error: null, display: 'website' },
        appointmentRequestEmail: { tab: 5, error: null, display: 'appointment request email' },
      },
      successResponse: null,
      errorResponse: null,
      refreshState: true,
    };
    this.getAutoCompleteAddress = this.getAutoCompleteAddress.bind(this);
    this.getAutoCompleteBillingAddress = this.getAutoCompleteBillingAddress.bind(this);
  };

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    if (inputs.billingSameAddress === true) {
      inputs.billingAddress = inputs.address;
      inputs.billingCity = inputs.city;
      inputs.billingAddress2 = inputs.address2;
      inputs.billingStreetNo = inputs.streetNo;
      inputs.billingState = inputs.state;
      inputs.billingZipCode = inputs.zipCode;
    }
    this.setState({
      inputs, refreshState
    });
  };

  onSubmit = () => {
      const { inputs } = this.state;
      inputs.phone = (inputs.phone) ? inputs.phone.replace(/[^A-Z0-9]+/ig, '') : inputs.phone;
      inputs.pharmacyPhone = (inputs.pharmacyPhone) ? inputs.pharmacyPhone.replace(/[^A-Z0-9]+/ig, '') : inputs.pharmacyPhone;
      if (!this.validate(inputs)) {
        this.props.toggleLoading(true);
        this.props.addNewFacility(inputs);
      }
  };

  closeModal = () => {

    let { inputs, refreshState } = this.state;
    inputs = {
      logo: null,
      logoIcon: null,
      name: '',
      displayName: '',
      abbreviation: '',
      groupAffiliation: '',
      address: '',
      streetNo: '',
      city: '',
      address2: '',
      state: '',
      zipCode: '',
      billingAddress: '',
      billingStreetNo: '',
      billingCity: '',
      billingAddress2: '',
      billingState: '',
      billingZipCode: '',
      billingEmail: '',
      billingTier: '',
      pharmacyPhone: '',
      pharmacyEmail: '',
      pharmacyWeb: '',
      subscription: '',
      notes: '',
      tabletsPurchased: '',
      billingSameAddress: false,
      phone: '',
      website: '',
    };
    this.props.initiatePopups();
    let { errors } = this.state;
    const error = false;
    errors = reset(errors);
    this.setState({
      modal: false, error, errors, successResponse: null, errorResponse: null,
      activeTab: '1', inputs, refreshState: !refreshState
    });
    this.props.closeFacilityPopup();
  };

  validate = (inputs) => {
    const { refreshState } = this.state;
    let { errors, activeTab } = this.state;
    const validationRules = {
      required: ['name', 'displayName', 'address', 'streetNo', 'city','address2', 'state', 'zipCode', 'billingAddress', 'billingAddress2', 'billingStreetNo','billingCity',
        'billingState', 'billingZipCode', 'billingEmail', 'pharmacyPhone', 'pharmacyEmail', 'phone', 'appointmentRequestEmail'],
      maxLimit: [
        {name: 'name', length: 100},
        {name: 'displayName', length: 25},
        {name: 'abbreviation', length: 10},
        {name: 'groupAffiliation', length: 50},
      ],
      exactLimit: [
        {name: 'phone', length: 10}, { name: 'pharmacyPhone', length: 10},
      ],
      email: ['billingEmail', 'appointmentRequestEmail', 'email', 'pharmacyEmail'],
      number: ['zipCode', 'billingZipCode', 'tabletsPurchased'],
      price: ['subscription'],
      url: ['website', 'pharmacyWeb']
    };
    errors = reset(errors);
    const validationResponse = validate(validationRules, inputs, errors);
    const error = validationResponse.error;
    errors = validationResponse.errors;

    const keys = Object.keys(errors);
    let errorTab = null;
    keys.map((inputKey) => {
      if (errors[inputKey].error !== null && errorTab === null) {
        errorTab = errors[inputKey].tab;
      }
    });
    activeTab = (errorTab) ? errorTab.toString() : activeTab;
    this.setState({errors, error, activeTab, errorResponse: null, successResponse: null, refreshState: !refreshState });
    return error;
  };

  toggle() {
    let { errors } = this.state;
    const error = false;
    errors = reset(errors);
    this.setState({
      modal: !this.state.modal, error, errors, successResponse: null, errorResponse: null, activeTab: '1'
    });
    this.props.closeFacilityPopup();
  };

  changeTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  getAutoCompleteAddress(data) {

    const { inputs, refreshState } = this.state;
    inputs.address = (data.address || data.address === '') ? data.address : inputs.address;
    inputs.streetNo = (data.streetNo || data.streetNo === '') ? data.streetNo : inputs.streetNo;
    inputs.city = (data.city || data.city === '') ? data.city : inputs.city;
    inputs.address2 = (data.address2 || data.address2 === '') ? data.address2 : inputs.address2;
    inputs.state = (data.state || data.state === '') ? data.state : inputs.state;
    inputs.zipCode = (data.zipCode || data.zipCode === '') ? data.zipCode : inputs.zipCode;
    inputs.placeId = (data.placeId || data.placeId === '') ? data.placeId : inputs.placeId;
    if (inputs.billingSameAddress === true) {
      inputs.billingAddress = inputs.address;
      inputs.billingCity = inputs.city;
      inputs.billingAddress2 = inputs.address2;
      inputs.billingStreetNo = inputs.streetNo;
      inputs.billingState = inputs.state;
      inputs.billingZipCode = inputs.zipCode;
    }
    this.setState({inputs, refreshState: !refreshState });
  };

  getAutoCompleteBillingAddress(data) {
    const { inputs, refreshState } = this.state;
    inputs.billingAddress = (data.address || data.address === '') ? data.address : inputs.billingAddress;
    inputs.billingAddress2 = (data.address2 || data.address2 === '') ? data.address2 : inputs.billingAddress2;
    inputs.billingCity = (data.city || data.city === '') ? data.city : inputs.billingCity;
    inputs.billingStreetNo = (data.streetNo || data.streetNo === '') ? data.streetNo : inputs.billingStreetNo;
    inputs.billingState = (data.state || data.state === '') ? data.state : inputs.billingState;
    inputs.billingZipCode = (data.zipCode || data.zipCode === '') ? data.zipCode : inputs.billingZipCode;
    this.setState({inputs, refreshState: !refreshState });
  };

  /* logo upload section */
  selectFile = () => {
    this.refs.inputUpload.click();
  };

  selectIconFile = () => {
    this.refs.inputIconUpload.click();
  };

  upload = (e) => {
    const imageType = e.target.getAttribute('data-image-type');
    const minImageResolution = (imageType === 'logo')
      ? constant.ERRORS.MIN_LOGO_IMAGE_RESOLUTION : constant.ERRORS.MIN_ICON_IMAGE_RESOLUTION;
    const minImageResolutionMessage = constant.ERRORS.MSG_MIN_LOGO_SIZE.replace(/#/g, minImageResolution);
    const file = e.target.files[0];
    let width = 0;
    let height = 0;
    let fileType = null;
    const _this = this;
    if (file) {
      fileType = file.type;
      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        this.setState({errorResponse: constant.ERRORS.MSG_IMAGE_INVALID})
      }
      let image = new Image();
      const url = window.URL || window.webkitURL;
      image.onload = function(){
        width = this.width;
        height = this.height;
        if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
          _this.setState({errorResponse: constant.ERRORS.MSG_IMAGE_INVALID});
        } else if(file.size >= 9000000) {
          _this.setState({errorResponse: constant.ERRORS.MSG_MAX_IMAGE});
        } else if (width < minImageResolution || height < minImageResolution) {
          _this.setState({errorResponse: minImageResolutionMessage});
        } else {
          _this.props.toggleLoading(true);
          _this.props.updateLogo(file, imageType);
        }
      };
      image.src = url.createObjectURL(file);
    }
  };

  onFileClick = (e) => {
    e.target.value = null
  };
  /* logo upload section end */

  componentDidMount(){
    this.props.initiatePopups();
    const { inputs } = this.state;
    inputs.logo = null;
    inputs.logoIcon = null;
    this.setState({ inputs, successResponse: null, errorResponse: null });
  };

  componentWillReceiveProps(np) {
    const { view } = np;

    if (np.facility.logo) {
      const { inputs } = this.state;
      inputs.logo = np.facility.logo.public_id.replace(`${config.PROFILE_IMAGE_FOLDER}/`, '');
      this.setState({ inputs, successResponse: null, errorResponse: null });
    } else {
      const { inputs } = this.state;
      inputs.logo = null;
      this.setState({ inputs });
    }
    if (np.facility.icon) {
      const { inputs } = this.state;
      inputs.logoIcon = np.facility.icon.public_id.replace(`${config.PROFILE_IMAGE_FOLDER}/`, '');
      this.setState({ inputs, successResponse: null, errorResponse: null });
    } else {
      const { inputs } = this.state;
      inputs.logoIcon = null;
      this.setState({ inputs });
    }
    if (np.facility.newFacilityResponse) {

      const message = 'Profile created successfully';
      this.setState({ successResponse: message, errorResponse: null});
      this.props.initiatePopups();
      setTimeout(() => {
        this.closeModal();
      }, config.POPUP_TIMEOUT_TIME);
    }
    if (np.facility.newFacilityErrorResponse) {
      if (np.facility.newFacilityErrorResponse.errorMessage) {
        this.setState({errorResponse: np.facility.newFacilityErrorResponse.errorMessage, successResponse: null});
      }
    }
    if (view === true && this.state.modal === false) {
      const inputs = {
        logo: null,
        logoIcon: null,
        name: '',
        displayName: '',
        abbreviation: '',
        groupAffiliation: '',
        address: '',
        streetNo: '',
        city: '',
        address2: '',
        state: '',
        zipCode: '',
        billingAddress: '',
        billingAddress2: '',
        billingStreetNo: '',
        billingCity: '',
        billingState: '',
        billingZipCode: '',
        billingEmail: '',
        billingTier: '',
        pharmacyPhone: '',
        pharmacyEmail: '',
        pharmacyWeb: '',
        subscription: '',
        notes: '',
        tabletsPurchased: '',
        billingSameAddress: false,
        phone: '',
        website: '',
      }

      this.setState({
        inputs,
        modal: true,
        successResponse: null,
        errorResponse: null
      });
    }
  };

  render() {
    const { inputs, errors, successResponse, errorResponse,
    } = this.state;

    const { handleSubmit } = this.props;
    const stripeUrl = config.STRIPE_URL;
    let logoImage, iconImage;
    logoImage =  `${process.env.PUBLIC_URL}/img/rectangle-no-image.png`;
    iconImage = `${process.env.PUBLIC_URL}/img/oval-no-image.png`;
    if (inputs.logo) {
      logoImage = `${config.FACILITY_PROFILE_IMAGE_PATH}/${inputs.logo}`;
    }
    if (inputs.logoIcon) {
      iconImage = `${config.FACILITY_PROFILE_ICON_IMAGE_PATH}/${inputs.logoIcon}`;
    }
    const form = (
      <Card>
        <CardBody>
          {successResponse !== null &&
          <div className="alert alert-success fade show" role="alert">
            <div className="alert__content"><p>{successResponse}</p></div>
          </div>
          }
          {errorResponse !== null &&
          <div className="alert alert-danger fade show" role="alert">
            <div className="alert__content"><p>{errorResponse}</p></div>
          </div>
          }
          <form className="form" onSubmit={handleSubmit} autoComplete={'off'}>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-12 loyal-image-upload-box">
                      <div className="row">
                        <div className="col-md-12">
                          <img className="loyal-uploaded-image-logo" src={logoImage} alt="logo" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <input accept="image/png, image/jpeg" onClick={this.onFileClick} onChange={this.upload}
                                 ref="inputUpload" type="file" style={{display: 'none'}} data-image-type="logo"/>
                          <Button onClick={this.selectFile} className="btn loyal-btn-outline-primary"
                                  color="primary">
                            <span className="lnr lnr-plus-circle"></span>
                            <span className="text">Upload Logo</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 loyal-image-upload-box">
                      <div className="row">
                        <div className="col-md-12">
                          <img className="loyal-uploaded-image" src={iconImage} alt="avatar" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <input accept="image/png, image/jpeg" onClick={this.onFileClick} onChange={this.upload}
                                 ref="inputIconUpload" type="file" style={{display: 'none'}} data-image-type="icon"/>
                          <Button onClick={this.selectIconFile} className="btn loyal-btn-outline-primary btn-green"
                                  color="primary">
                            <span className="lnr lnr-plus-circle"></span>
                            <span className="text">Upload Icon</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9 loyal-facility-tab-container">
                  <div>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={{ active: this.state.activeTab === '1' }}
                          onClick={() => { this.changeTab('1'); }}>Basic Details</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={{ active: this.state.activeTab === '2' }}
                          onClick={() => { this.changeTab('2'); }}>Physical Address</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={{ active: this.state.activeTab === '3' }}
                          onClick={() => { this.changeTab('3'); }}>Billing Information</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={{ active: this.state.activeTab === '4' }}
                          onClick={() => { this.changeTab('4'); }}>Pharmacy Details</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={{ active: this.state.activeTab === '5' }}
                          onClick={() => { this.changeTab('5'); }}>Contact Details</NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      {/* basic details tab */}
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Facility Name <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="name"
                                        type="text"
                                        maxLength={100}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.name.error && <Error text={errors.name.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Abbreviation</span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="abbreviation"
                                        type="text"
                                        maxLength={10}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.abbreviation.error && <Error text={errors.abbreviation.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Display Name <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="displayName"
                                        type="text"
                                        maxLength={25}
                                        onChange = {this.onChangeFields}
                                      />
                                      <span className="input-help">{25 - inputs.displayName.length} characters remaining</span>

                                      {errors.displayName.error && <Error text={errors.displayName.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Group Affiliation</span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="groupAffiliation"
                                        type="text"
                                        maxLength={50}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.groupAffiliation.error && <Error text={errors.groupAffiliation.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      {/* basic details tab */}
                      {/* physical address tab */}
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Address 1 <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <PlacesAutoComplete
                                        value={inputs.streetNo}
                                        readOnly={false}
                                        getAddress={this.getAutoCompleteAddress} />
                                      {errors.address.error && <Error text={errors.address.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Address 2</span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="address2"
                                        type="text"
                                        value={inputs.address2}
                                        onChange = {this.onChangeFields}
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-2">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">City <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="city"
                                        type="text"
                                        value={inputs.city}
                                        maxLength={30}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.city.error && <Error text={errors.city.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">State <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="state"
                                        type="text"
                                        value={inputs.state}
                                        maxLength={30}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.state.error && <Error text={errors.state.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Zip code <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="zipCode"
                                        type="text"
                                        value={inputs.zipCode}
                                        maxLength={5}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.zipCode.error && <Error text={errors.zipCode.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </TabPane>
                      {/* physical address tab */}
                      {/* billing info tab */}
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">

                            <div className="row">
                              <div className="col-md-6 text-left">
                                <div className="checkbox-wrapper">
                                  <label className="checkbox-container terms-and-conditions">
                                    <input
                                      id="billingSameAddress"
                                      className="custom-checkbox"
                                      type="checkbox"
                                      name="billingSameAddress"
                                      onChange={this.onChangeFields}
                                    />
                                    <span className="checkmark"/>
                                    <label className="form-check-label" htmlFor="billingSameAddress">Same as physical address</label>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 text-right">
                                <a href={stripeUrl} target="_blank" className="btn loyal-btn-outline-primary">
                                  Charge Bill
                                </a>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Address 1<span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <PlacesAutoComplete
                                        value={inputs.billingStreetNo}
                                        readOnly={inputs.billingSameAddress}
                                        getAddress={this.getAutoCompleteBillingAddress} />
                                      {errors.billingAddress.error && <Error text={errors.billingAddress.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Billing Tier</span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <select name="billingTier" id="billingTier"
                                              className="form-control form-select"
                                              onChange = {this.onChangeFields}>
                                        <option value=''>Select</option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                        <option value='3'>3</option>
                                        <option value='4'>4</option>
                                        <option value='5'>5</option>
                                      </select>
                                      {errors.billingTier.error && <Error text={errors.billingTier.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Subscription</span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="subscription"
                                        type="text"
                                        maxLength={9}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.subscription.error && <Error text={errors.subscription.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Address 2</span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="billingAddress2"
                                        type="text"
                                        readOnly={inputs.billingSameAddress}
                                        value={inputs.billingAddress2}
                                        maxLength={30}
                                        onChange = {this.onChangeFields}
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Tablets Purchased</span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="tabletsPurchased"
                                        type="text"
                                        maxLength={4}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.tabletsPurchased.error && <Error text={errors.tabletsPurchased.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form__form-group">
                                      <span className="form__form-group-label">City <span className={'required'}>*</span></span>
                                      <div className="form__form-group-field">
                                        <FormGroup>
                                          <input
                                            className="form-control"
                                            name="billingCity"
                                            type="text"
                                            readOnly={inputs.billingSameAddress}
                                            value={inputs.billingCity}
                                            maxLength={30}
                                            onChange = {this.onChangeFields}
                                          />
                                          {errors.billingCity.error && <Error text={errors.billingCity.error}/>}
                                        </FormGroup>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form__form-group">
                                      <span className="form__form-group-label">State <span className={'required'}>*</span></span>
                                      <div className="form__form-group-field">
                                        <FormGroup>
                                          <input
                                            className="form-control"
                                            name="billingState"
                                            type="text"
                                            readOnly={inputs.billingSameAddress}
                                            value={inputs.billingState}
                                            maxLength={30}
                                            onChange = {this.onChangeFields}
                                          />
                                          {errors.billingState.error && <Error text={errors.billingState.error}/>}
                                        </FormGroup>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form__form-group">
                                      <span className="form__form-group-label">Zip code <span className={'required'}>*</span></span>
                                      <div className="form__form-group-field">
                                        <FormGroup>
                                          <input
                                            className="form-control"
                                            name="billingZipCode"
                                            type="text"
                                            readOnly={inputs.billingSameAddress}
                                            value={inputs.billingZipCode}
                                            maxLength={5}
                                            onChange = {this.onChangeFields}
                                          />
                                          {errors.billingZipCode.error && <Error text={errors.billingZipCode.error}/>}
                                        </FormGroup>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form__form-group">
                                      <span className="form__form-group-label">Billing Email <span className={'required'}>*</span></span>
                                      <div className="form__form-group-field">
                                        <FormGroup>
                                          <input
                                            className="form-control"
                                            name="billingEmail"
                                            type="text"
                                            maxLength={100}
                                            onChange = {this.onChangeFields}
                                          />
                                          {errors.billingEmail.error && <Error text={errors.billingEmail.error}/>}
                                        </FormGroup>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Notes </span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <textarea name="notes"
                                                className="form-control facility-area"
                                                onChange = {this.onChangeFields}
                                                maxLength={250}>
                                      </textarea>
                                      {errors.notes.error && <Error text={errors.firstName.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">

                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      {/* billing info tab */}
                      {/* pharmacy details tab */}
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Pharmacy Contact Phone <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <MaskedInput mask="(111) 111-1111"
                                                   className="form-control"
                                                   name="pharmacyPhone"
                                                   type="text"
                                                   placeholder={''}
                                                   maxLength={10}
                                                   value={inputs.pharmacyPhone}
                                                   onChange = {this.onChangeFields}/>
                                      {errors.pharmacyPhone.error && <Error text={errors.pharmacyPhone.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Receive Request Email <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                  <FormGroup>
                                      <input
                                        className="form-control"
                                        name="pharmacyEmail"
                                        type="text"
                                        value={inputs.pharmacyEmail}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.pharmacyEmail.error && <Error text={errors.pharmacyEmail.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Pharmacy Web URL</span>
                                  <div className="form__form-group-field">
                                  <FormGroup>
                                      <input
                                        className="form-control"
                                        name="pharmacyWeb"
                                        type="text"
                                        maxLength={100}
                                        value={inputs.pharmacyWeb}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.pharmacyWeb.error && <Error text={errors.pharmacyWeb.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      {/* pharmacy details tab */}
                      {/* contact details tab */}
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="12">

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Phone <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <MaskedInput mask="(111) 111-1111"
                                                   className="form-control"
                                                   name="phone"
                                                   type="text"
                                                   placeholder={''}
                                                   maxLength={10}
                                                   onChange = {this.onChangeFields}/>
                                      {errors.phone.error && <Error text={errors.phone.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Website</span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="website"
                                        type="text"
                                        maxLength={100}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.website.error && <Error text={errors.website.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Email to Receive Appointment Requests <span className={'required'}>*</span></span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="appointmentRequestEmail"
                                        type="text"
                                        maxLength={100}
                                        onChange = {this.onChangeFields}
                                      />
                                      {errors.appointmentRequestEmail.error && <Error text={errors.appointmentRequestEmail.error}/>}
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">Email</span>
                                  <div className="form__form-group-field">
                                    <FormGroup>
                                      <input
                                        className="form-control"
                                        name="email"
                                        type="text"
                                        value={inputs.email}
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      {/* ontact details tab */}
                    </TabContent>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-right">
                  <Button className={'clear'} color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                  <Button className={'save-update'} color="primary" onClick={this.onSubmit}>Save</Button>
                </div>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    );

    return (
      <Modal isOpen={this.state.modal}
             size={'lg'}
             backdrop={'static'}
             className={'model-form modal-dialog-facility'}>
        <ModalHeader className={'header-form'}>Add New Facility</ModalHeader>
        <ModalBody>
          {form}
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  toggleLoading, updateLogo, addNewFacility, initiatePopups
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFacility)
