import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import {Prompt} from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Label, Input } from 'reactstrap';
import HeadingText from '../../../shared/components/form/HeadingText';
import CustomSelectBox from './Components/CustomSelectWithDefaultSelectComponent';
import constants from '../../../constants/constants';
import ResponseMessages from '../../../constants/responseMessages';
import { toggleLoading } from '../../../redux/actions/commonActions';
import { save, uploadImage, clearAll, removeImage } from '../../../redux/actions/broadcastActions';
import Error from '../../../shared/components/form/Error';
import { getAllFacilities } from '../../../redux/actions/staffActions';
import localStorage from '../../../libs/storageHelper';
import config from '../../../config/app.config';

let defaultListLoaded = false;

class Broadcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      uploader1: null,
      uploader2: null,
      uploader3: null,
      errorImage: null,
      imageRatio1: 0,
      imageRatio2: 0,
      imageRatio3: 0,
      errorContent: null,
      facilityAll: [],
      audience: ['all_users'],
      errorAudience: null,
      facility: [],
      errorFacility: null,
      successResponse: null,
      errorResponse: null,
      imageRatios:{},
      isFieldsChanged: false,
      changedFields: [],
      isClicked: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.validate = this.validate.bind(this);
    this.remove = this.remove.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  componentWillMount() {
    this.props.toggleLoading(true);
  }

  componentWillUnmount(){
    if(this.props.common.loading){
      this.props.toggleLoading(false);
    }
  }

  validate(){
    const { content, audience, facility } = this.state;
    const loggedUser = localStorage.getFromStorage('loggedUser');
    let errorContent = null;
    let errorAudience = null;
    let errorFacility = null;

    if (!content || content.trim() === '') {
      errorContent = 'Content cannot be empty';
    } else {
      errorContent = null;
    }

    if (audience.length === 0) {
      errorAudience = 'Audience cannot be empty';
    } else {
      errorAudience = null;
    }

    if ((loggedUser && loggedUser.user.type === 'super_admin') && facility.length === 0) {
      errorFacility = 'Facility cannot be empty';
    } else {
      errorFacility = null;
    }

    this.setState({
      errorContent,
      errorAudience,
      errorFacility
    });

    if(!errorContent && !errorAudience && !errorFacility) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.getAllFacilities();
  }

  handleSubmit(){
    if(!this.validate()) {
      return;
    }
    const loggedUser = localStorage.getFromStorage('loggedUser');
    const { uploader1, uploader2, uploader3,imageRatio1, imageRatio2, imageRatio3, content, audience, facility: facilities } = this.state;
    const imagesList = [];
    const imagesRatioList = {};

    if(uploader1){
      imagesList.push(uploader1);
      imagesRatioList[uploader1] = imageRatio1;
    }
    if(uploader2){
      imagesList.push(uploader2);
      imagesRatioList[uploader2] = imageRatio2;
    }
    if(uploader3){
      imagesList.push(uploader3);
      imagesRatioList[uploader3] = imageRatio3;
    }

    const data = {
      content,
      images: imagesList,
      imageRatios: imagesRatioList,
      audience,
      facilities
    }

    if (loggedUser && loggedUser.user.type === 'admin') {
      data.facilities = [loggedUser.user.facility];
    } else {
      data.facilities = facilities;
    }
    this.setState({isClicked : true, isFieldsChanged: false, content: '', uploader1: null, uploader2: null, uploader3: null }, () => {
      this.props.toggleLoading(false);
    });
    this.props.save(data);
  }

  remove(id) {
    const state = this.state;
    this.props.removeImage({ image: `newsfeed/${state[id]}` });
    state[id] = null;
    this.setState(state);
    this.handleFormChange('remove', id);
    this.props.clearAll();
  }

  handleOnChange(e){
    const value = e.target.value;
    const name = e.target.name;

    const state = this.state;
    state[name] = value;

    this.setState(state);
    if(value !== '' && value) {
      this.handleFormChange('add', name);
    } else {
      this.handleFormChange('remove', name);
    }
  }

  handleFormChange(type, value){
    const { changedFields } = this.state;
    let { isFieldsChanged } = this.state;
    const index = changedFields.indexOf(value);

    if(type === 'add') {
      if(index === -1){
        changedFields.push(value);
      }
    }else {
      if(index !== -1){
        changedFields.splice(index, 1);
      }
    }

    if(changedFields.length > 0){
      isFieldsChanged = true;
    }else{
      isFieldsChanged = false;
    }

    this.setState({
      isFieldsChanged,
      changedFields
    });
  }

  componentWillUnmount() {
    this.props.clearAll();
  }
  componentWillReceiveProps(np) {
    const loggedUser = localStorage.getFromStorage('loggedUser');
    const { BROADCAST_TYPES } = constants;
    if(np.broadcast && np.broadcast.uploadedImage) {
      const state = this.state;
      state[np.broadcast.uploadedImage.uploader] = np.broadcast.uploadedImage.public_id.replace("newsfeed/", "");


      this.setState(state);
    }
    if(np.staff.facilityAll) {
      this.setState({ facilityAll:np.staff.facilityAll });
    }
    if((loggedUser && loggedUser.user.type === 'super_admin') && !defaultListLoaded && np.staff.facilityAll) {
      const facility = np.staff.facilityAll.map(filter => filter.id);
      const audience = BROADCAST_TYPES.map(filter => filter.id);
      this.setState({
        facility,
        audience
      });
    }
    if (loggedUser && loggedUser.user.type === 'admin') {
      const audience = BROADCAST_TYPES.map(filter => filter.id);
      this.setState({
        audience
      });
    }
    if (np.broadcast.createNewsData) {
      const { facilityAll } = this.state;
      this.setState({
        successResponse: 'News broadcasted successfully'
      });
      setTimeout(() => {
        this.props.clearAll();
        this.setState({
          content: '',
          uploader1: null,
          uploader2: null,
          uploader3: null,
          errorImage: null,
          errorContent: null,
          audience: BROADCAST_TYPES.map(filter => filter.id),
          errorAudience: null,
          facility: facilityAll.map(filter => filter.id),
          errorFacility: null,
          successResponse: null,
          errorResponse: null,
          isClicked: false
        });
       }, config.POPUP_TIMEOUT_TIME);
    }
  }

  upload = (e) => {
    const uploader = e.target.id;
    const ratio = e.target.name;
    const file = e.target.files[0];
    let width = 0;
    let height = 0;
    let fileType = null;
    const _this = this;
    if(file) {
      fileType = file.type;

      const extension = file.name.split('.')[1];
      const fileTypes = ['jpeg', 'jpg', 'png', 'JPEG','JPG', 'PNG'];

      if ((fileType !== 'image/jpeg' && fileType !== 'image/png') || !fileTypes.includes(extension)) {
        this.setState({errorImage: ResponseMessages.ERRORS.MSG_IMAGE_INVALID})
      } else {
        let image = new Image();
        const url = window.URL || window.webkitURL;
        image.onload = function(){
          width = this.width;
          height = this.height;

          if(file.size >= 9000000) {
            _this.setState({errorImage: ResponseMessages.ERRORS.MSG_MAX_IMAGE})
          } else if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
            _this.setState({errorImage: ResponseMessages.ERRORS.MSG_IMAGE_INVALID})
          } else {
            const ratioValue = width/height;
            _this.setState({ errorImage: null, [ratio]:ratioValue });
            _this.props.toggleLoading(true);

            _this.props.uploadImage(file, uploader);
          }
        };
        image.src = url.createObjectURL(file);
        this.handleFormChange('add', e.target.id);
      }
    }

  }

  handleFilter = (name, list) => {
    this.setState({
      [name]: list
    }, () => {
      const { facilityAll } = this.state;
      const { BROADCAST_TYPES } = constants;
      const facilityList = facilityAll.map(filter => filter.id);
      const broadcastList = BROADCAST_TYPES.map(filter => filter.id);
      if((name === 'audience' && JSON.stringify(list) === JSON.stringify(broadcastList))
      || (name === 'facility' && JSON.stringify(list) === JSON.stringify(facilityList))) {
        this.handleFormChange('remove', name);
      } else {
        this.handleFormChange('add', name);
      }
    });
  }

  render() {
    const { content, uploader1, uploader2, uploader3, errorContent, errorImage, errorAudience,
      successResponse, errorResponse,
      errorFacility, facilityAll, facility, audience, isFieldsChanged, isClicked } = this.state;

    const { BROADCAST_TYPES } = constants;
    const loggedUser = localStorage.getFromStorage('loggedUser');
    return (
      <div className="broadcast-news">
        <Prompt when={isFieldsChanged} message="Changes you made will be lost if you navigate away from this page. Are you sure you want to leave this page?"/>
        <Col md={12} lg={12} >
        <Card>
          <CardBody>
          {successResponse !== null &&
          <div className="alert alert-success fade show" role="alert">
            <div className="alert__content"><p>{successResponse}</p></div>
          </div>
          }
          {errorResponse !== null &&
          <div className="alert alert-danger fade show" role="alert">
            <div className="alert__content"><p>{errorResponse}</p></div>
          </div>
          }
              <div>
                <HeadingText text={ loggedUser && loggedUser.user.type === 'super_admin' ? "Create Broadcast" : "Create Broadcast News" } />
                <p className="sub-title">
                  {
                    ( loggedUser && loggedUser.user.type === 'super_admin') ? 'Share content on the newsfeeds of Loyal Pet users' :
                    'Share relevant content on the newsfeeds of your Loyal Pet users'
                  }
                  </p>
              </div>
              <div className="row">
                <Col md={12}>
                  <FormGroup>
                    <Label for="" className="content-label">Content</Label>
                    <Input
                      maxLength={300}
                      name="content" onChange={this.handleOnChange}
                      value={content}
                      type="textarea" className="content" placeholder="Enter text…"/>
                      {errorContent && <Error text={errorContent}/>}
                  </FormGroup>
                </Col>
              </div>
              <div className="row">
                <Col>
                  <div className="image-uploader-container clearfix">
                    <div className="image-uploaders-wrapper">
                      {
                        uploader1 ?
                        <div className="loaded-img">
                          <img src={`${config.CLOUDNARY_VIEW_URL}newsfeed/${uploader1}`} alt=""/>
                          <span onClick={() => { this.remove('uploader1') }}></span>
                        </div> :
                        <Fragment>
                          <Label className="image-uploader" for="uploader1"></Label>
                          <Input className="uploader" id="uploader1" name="imageRatio1" accept="image/png, image/jpeg"
                          onChange={this.upload} type="file" />
                        </Fragment>
                      }
                    </div>
                    <div className="image-uploaders-wrapper">
                      {
                        uploader2 ?
                        <div className="loaded-img">
                          <img src={`${config.CLOUDNARY_VIEW_URL}newsfeed/${uploader2}`} alt=""/>
                          <span onClick={() => { this.remove('uploader2') }}></span>
                        </div> :
                        <Fragment>
                          <Label className="image-uploader" for="uploader2"></Label>
                          <Input className="uploader" id="uploader2" name="imageRatio2" accept="image/png, image/jpeg"
                          onChange={this.upload} type="file" />
                        </Fragment>
                      }
                    </div>
                    <div className="image-uploaders-wrapper">
                      {
                        uploader3 ?
                        <div className="loaded-img">
                          <img src={`${config.CLOUDNARY_VIEW_URL}newsfeed/${uploader3}`} alt=""/>
                          <span onClick={() => { this.remove('uploader3') }}></span>
                        </div> :
                        <Fragment>
                          <Label className="image-uploader" for="uploader3"></Label>
                          <Input className="uploader" id="uploader3" name="imageRatio3" accept="image/png, image/jpeg"
                          onChange={this.upload} type="file" />
                        </Fragment>
                      }
                    </div>
                  </div>
                  {errorImage && <Error text={errorImage}/>}
                </Col>
              </div>
              <div className="dropdowns-wrapper">
              <div className="row">
                <div className="col-sm-3">
                  <FormGroup>
                    <Label for="exampleSelect">Select Audience</Label>
                      <CustomSelectBox
                        listName="audience"
                        allItems={BROADCAST_TYPES}
                        isSelectAllRequired={false}
                        placeHolderSingular="audience"
                        placeHolderPlural="Audience"
                        selectedItems={audience}
                        disableValue="all_users"
                        action={this.handleFilter}
                      />
                      {errorAudience && <Error text={errorAudience}/>}
                  </FormGroup>
                </div>
                {
                  (loggedUser && loggedUser.user.type === 'super_admin') &&
                  <div className="col-sm-3">
                  <FormGroup>
                    <Label for="exampleSelect">Select Facility</Label>
                    <CustomSelectBox
                        listName="facility"
                        allItems={facilityAll}
                        isSelectAllRequired={true}
                        placeHolderSingular="facility"
                        placeHolderPlural="Facilities"
                        selectedItems={facility}
                        action={this.handleFilter}
                      />
                      {errorFacility && <Error text={errorFacility}/>}
                  </FormGroup>
                </div>
                }
              </div>
              </div>
              <div className="row">
                <Col md={12}>
                  {
                    !isClicked?
                      <button onClick={this.handleSubmit} type="button"
                              className="btn btn-primary btn-broadcast">Broadcast</button>
                      :
                      <button type="button"
                              className="btn btn-primary btn-broadcast">Broadcast</button>
                  }

                </Col>
              </div>
          </CardBody>
        </Card>
        </Col>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getAllFacilities,
  toggleLoading,
  uploadImage,
  save,
  clearAll,
  removeImage
};

export default connect(mapStateToProps, mapDispatchToProps)(Broadcast)
